/*
 * Copyright 2024 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { createApiRef } from '@backstage/core-plugin-api';
import {
  CopilotActivityMetrics,
  CopilotIDECodeCompletionMetrics,
  MetricsType,
  PeriodRange,
  SeatInfo,
  TeamInfo,
} from '@hinge-health/plugin-copilot-common';

export const copilotApiRef = createApiRef<CopilotApi>({
  id: 'plugin.copilot.service',
});

export interface CopilotApi {
  getMetrics(
    startDate: Date,
    endDate: Date,
    type: MetricsType,
    team?: string,
  ): Promise<CopilotIDECodeCompletionMetrics[]>;
  fetchTeams(
    startDate: Date,
    endDate: Date,
    type: MetricsType,
  ): Promise<string[]>;
  periodRange(type: MetricsType): Promise<PeriodRange>;
  fetchCopilotBillingCycleInfo(): Promise<SeatInfo>;
  fetchAllTeams(): Promise<TeamInfo[]>;
  fetchCopilotUsersStats(
    team?: string,
  ): Promise<{ total: number; active: number; pending_cancellation: number }>;
  fetchCopilotActivity(
    startDate: Date,
    endDate: Date,
    team?: string,
  ): Promise<CopilotActivityMetrics[]>;
}
